import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="C" src="C" />,
    name: 'Dr. David C',
    occupation: 'General Practitioner',
    testimonial:
      "I've only been using it since lunch time today and already it has significantly enhanced my workflow.",
  },
  {
    avatar: <Avatar alt="B" src="B" />,
    name: 'Dr. Grant B',
    occupation: 'General Practitioner',
    testimonial:
      "I thought it performed really well and was very helpful in reducing admin time. The voice recognition is far better than that used on outlook.",
  },
  {
    avatar: <Avatar alt="P" src="P" />,
    name: 'Dr. Darren P',
    occupation: 'General Practitioner',
    testimonial:
      'I am loving this feature. I really like the ease of recording consults and how it structures the notes. It follows a logical structure in note making too.',
  },
  {
    avatar: <Avatar alt="W" src="W" />,
    name: 'Dr. Melinda W',
    occupation: 'General Practitioner',
    testimonial:
      "Perfect!! I was very impressed and really enjoyed the functionality. Patients also expressed how impressed they were. There was more time engaging and less time typing. LOVED how it summarised! Really amazing!",
  },
  {
    avatar: <Avatar alt="O" src="O" />,
    name: 'Dr. Olusola O',
    occupation: 'General Practitioner',
    testimonial:
      "This is beautiful! Nora is the bomb. It has taken over my heart.",
  },
  {
    avatar: <Avatar alt="H" src="H" />,
    name: 'Dr. Michael H',
    occupation: 'General Practitioner',
    testimonial:
      "The quality of this product exceeded my expectations. It's durable, well-designed, and built to last. Definitely worth the investment!",
  },
];

export default function Testimonials() {
  useTheme();

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Testimonials
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
        Don’t just take our word for it! See what our customers have to say about their experience with Nora.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.secondary' }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}