import * as React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Footer from './components/Footer';
import getLPTheme from '../home/getLPTheme';
import AppAppBar from './components/AppAppBar';
import './privacypolicy.css';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function PrivacyPolicy() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default', mt: { xs: 2, sm: 3, md: 4 } }}>
        <div className="terms-container">
          <div className="terms-text" style={{ fontSize: '14px', textAlign: 'center' }}>
            <h1>Privacy Policy</h1>
            <p>Effective Date: October 3, 2024</p>
            <p>
              This Privacy Policy applies to all users of software and services provided by Getech Education Ltd, trading as Nora. 
              Nora is a UK registered company, and we provide software designed to record doctor-patient conversations and generate 
              structured clinical notes. By using our products, you agree to comply with these terms.
            </p>

            <h2>Company Details</h2>
            <ul>
              <li>Registered Name: Getech Education Ltd</li>
              <li>Trading Name: Nora</li>
              <li>Registered Company Number: 14245329</li>
              <li>Website: <a href="http://www.nora-ai.com">www.nora-ai.com</a></li>
              <li>Email: <a href="mailto:info@nora-ai.com">info@nora-ai.com</a></li>
            </ul>

            <h2>Who are we?</h2>
            <p>
              We are Nora, a company that offers AI-powered software to record doctors' conversations with their patients and 
              automatically generate structured clinical notes. We also provide a feature for dictating notes outside of consultations. 
              Nora is fully integrated within Healthbridge, an EHR software vendor, which is currently the only way to access our services.
            </p>

            <h2>What are you reading?</h2>
            <p>
              This policy explains: which data we collect, how we use, store, and protect it and which privacy rights you can invoke 
              (the Privacy Policy). We may modify this Privacy Policy. If we substantially modify the Privacy Policy, we shall place a 
              notification on our Website and in our App. In addition, we shall notify registered users in case of a substantial modification via email. 
              If you are not a registered user, we advise you to consult the Website, App, and this Privacy Policy regularly.
            </p>

            <h2>Questions?</h2>
            <p>If you have any questions regarding this Privacy Policy, do not hesitate to contact us by sending an email to <a href="mailto:info@nora-ai.com">info@nora-ai.com</a>.</p>

            <h2>1. NORA'S ROLE</h2>
            <p>
              Since Nora is not an active participant in conversations between physicians and their patients, we do not have complete insight into 
              all information exchanged during conversations. Therefore, patients should always contact their physician or medical organization 
              directly for any specific requests related to medical consultations. Please note that in some cases, your request may be referred to the relevant Medical Organisation.
            </p>

            <h2>2. DATA PROCESSING</h2>
            <p>
              We process personal data on the basis of the following grounds included in the General Data Protection Regulation (GDPR): Consent, 
              Execution of an Agreement (including Software as a Service, or SaaS), Legal Obligation, and Legitimate Interest of Nora.
            </p>
            <h3>Overview of Data Processing</h3>
            <ul>
              <li><b>a) Get in touch:</b> When filling out our digital contact form, you provide your name, email, phone number, and request description. We only process this data to provide you with the information you have requested.</li>
              <li><b>b) Communication:</b> We may use your contact information to send information messages necessary for the use of Nora, excluding marketing communications.</li>
              <li><b>c) Product Improvement:</b> Nora may store the Recordings, Transcripts, Generated Notes, User Edited Notes, and Other Data to improve Nora's technologies in any way that Nora deems necessary to enhance user value.</li>
              <li><b>d) Data Aggregation:</b> We may aggregate or encrypt personal data to create anonymous data that cannot be traced back to a natural person.</li>
              <li><b>e) Legal Compliance:</b> In order to comply with applicable law, Nora may be required to process personal data for purposes other than those set out in this Privacy Policy, such as for law enforcement.</li>
              <li><b>f) Fraud Detection:</b> We process personal data for fraud detection and to prevent abuse. If fraud or criminal activity is suspected, personal data may be processed to prevent future use of Nora.</li>
              <li><b>g) Customer Service:</b> We collect and share personal data to provide customer service.</li>
              <li><b>h) Marketing:</b> We may use contact information for marketing communications, with the option to unsubscribe.</li>
            </ul>

            <h2>3. DATA STORAGE</h2>
            <p>
              We do not store any patient identifiers, such as names, birth dates, or locations. All consultation data is anonymized after 60 days, ensuring that both doctors and patients are unidentifiable. We store personal data 
              only as long as necessary for the purposes mentioned in Section 2 (Data Processing) and as required under applicable law. Anonymous data may be retained indefinitely for statistical or analytical purposes.
            </p>

            <h2>4. DATA PROTECTION</h2>
            <p>
              We do our utmost to protect your personal data by taking technical and organisational security measures. Access to personal 
              data is strictly limited, and personal data stored in the cloud is encrypted with 2FA.
            </p>

            <h2>5. DATA SHARING</h2>
            <p>In the cases listed below, Nora shares personal data with third parties.</p>
            <ul>
              <li><b>a) Service Providers:</b> Nora may share personal data with the following categories of service providers, who contribute to Nora: hosting, data analysis and storage, payment processing, information technology and related infrastructure, customer service, product design, product diagnostics, email delivery, credit card processing, auditing, and marketing. Nora only shares personal data with service providers that provide sufficient safeguards to protect personal data. </li>
              <li><b>b) Competent Authorities:</b> Nora discloses personal data to law enforcement authorities and other public authorities to the extent required by law or strictly necessary for the prevention, detection or prosecution of criminal offences and fraud. </li>
              <li><b>c) Transfer:</b> We may transfer personal data to third parties in the event that Nora is subject to a merger, acquisition, reorganisation, sale of business units or bankruptcy. In this case Nora will ask for your prior consent, if this is required under applicable law. </li>
              <li><b>d) International Transfer:</b> Your data may be transferred and processed in countries other than your own. These transfers are conducted in compliance with relevant data protection laws and under strict confidentiality and security measures.</li>
            </ul>

            <h2>6. PRIVACY RIGHTS</h2>
            <p>
              Under GDPR, you have the right to:</p>
            <ul>
              <li>request access to your Personal Data;</li>
              <li>request us to correct, limit or delete your personal data; request a copy of your personal data. We can provide this copy to third parties at your request;</li>
              <li>object to the processing of your personal data by contacting us; </li>
              <li>revoke your consent to the processing of your personal data; </li>
              </ul>

            <h2>7. LIMITATION OF LIABILITY</h2>
            <p>
              We are not liable for any data leaks caused by the user or third parties (e.g., AWS leaks). Additionally, we are not liable for any data leaks on our side if we can prove that we have taken the necessary precautions to protect your data.
            </p>

            <h2>8. GOVERNING LAW</h2>
            <p>
              This Privacy Policy is governed by and construed in accordance with the laws of the jurisdiction in which we operate. Any 
              disputes will be subject to the jurisdiction of courts in England and Wales.
            </p>
          </div>
        </div>
        <Divider />
        <Footer />
      </Box>
      <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      />
    </ThemeProvider>
  );
}
